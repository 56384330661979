import React from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

import ImageAbout from '../images/img-about.png'
import ImageAbout2 from '../images/img-about@2x.png'
import ImageAbout3 from '../images/img-about@3x.png'

import ImageLandscape from '../images/img-duotono.png'
import ImageLandscape2 from '../images/img-duotono@2x.png'
import ImageLandscape3 from '../images/img-duotono@3x.png'

import IntersectionImage from '../images/intersection-1.svg'
import Intersection2Image from '../images/intersection-2.svg'
import GNPLogo from '../images/gnp-logo.svg'

// sponsors
import group51 from '../images/group-51.svg'
import group52 from '../images/group-52.svg'
import group53 from '../images/group-53.svg'
import group54 from '../images/group-54.svg'

// collaborators
import group40 from '../images/group-40.svg'
import group41 from '../images/group-41.svg'
import group44 from '../images/group-44.svg'
import group47 from '../images/group-47.svg'
import group48 from '../images/group-48.svg'
import group49 from '../images/group-49.svg'

//Video
import homeVideoMp4 from '../images/video/home/homeVideoMp4.mp4'
import homeVideoMobileMp4 from '../images/video/home/homeVideoMobile-muted.mp4'

import homeVideoWebm from '../images/video/home/homeVideoWebm.webm'
import homeVideoMobileWebm from '../images/video/home/homeVideoMobileWebm2-muted.webm'

//import { CustomView, isMobileSafari } from 'react-device-detect'

const sponsorCompanies = [
  {
    image: group51,
    alt: 'jalisco',
  },
  {
    image: group52,
    alt: 'tane',
  },
  {
    image: group54,
    alt: 'nesspresso',
  },
  {
    image: group53,
    alt: 'volcan',
  },
]

const collaboratorCompanies = [
  {
    image: group40,
    alt: 'anthro',
  },
  {
    image: group41,
    alt: 'makken',
  },
  {
    image: group44,
    alt: 'm',
  },
  {
    image: group47,
    alt: 'costa',
  },
  {
   image: group48,
   alt: 'elcareyes',
  },
  {
    image: group49,
    alt: 'careyesfundation'
  }
]

function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  )
}

const IndexPage = () => {
  const goToTickets = () => {
    navigate('/boletos')
  }

  const goToHosting = () => {
    navigate('/hospedaje')
  }

  return (
    <Layout
      gradient={{
        background:
          'linear-gradient(to bottom, rgba(32, 41, 69, 0.65), rgba(32, 41, 69, 0))',
      }}
    >
      <SEO title="Arte Careyes 2020" description="Arte Careyes, festival cultural de México que reúne y expone el talento mexicano contemporáneo e internacional. Ven y coincide con mentes de diferentes expresiones artísticas en espacios geográficos sorprendentes." />
      <div className="home__main-image">
        <video
          className="home__main-video-web"
          height="100%"
          width="100%"
          loop
          muted
          autoPlay
        >
          <source src={homeVideoMp4} type="video/mp4" />
          <source src={homeVideoWebm} type="video/webm" />
        </video>
        <video
          className="home__main-video-mobile"
          height="100%"
          width="100%"
          loop
          autoPlay
          playsInline
        >
          <source src={homeVideoMobileMp4} type="video/mp4" />
          <source src={homeVideoMobileWebm} type="video/webm" />
        </video>
      </div>
      <section className="home__about" id="home-about">
        <Fade left duration={2000}>
          <header className="about__title">
            <h1 className="about__title-header">About</h1>
            <h1 className="about__title-header">Arte Careyes</h1>
            <h3 className="about__title-subheader">
              Otoño 2020
            </h3>
          </header>
        </Fade>
        <div className="about-section">
          <img
            src={IntersectionImage}
            className="about-section__image-decorator"
            alt="decorator"
          />
          <div className="about-section__container-decorator" />
          <section className="about__content">
            <Fade delay={300} duration={2000}>
              <p className="about__content-main">
                Arte Careyes es un punto de reunión para descubrir el talento de
                artistas mexicanos e internacionales del arte, el cine y la
                música.
                <br />
                Actualmente, es considerado como uno de los principales
                festivales de cultura en México que fusiona diferentes ramas del
                arte.
                <br />
                Este 2020, celebramos 10 años de enaltecer la belleza de nuestro
                país y sus expresiones artísticas en un evento exclusivo y
                enriquecedor.
                <br />
              </p>
            </Fade>
            <Fade delay={450} duration={2000}>
              <Button onClick={goToTickets}>BOLETOS</Button>
            </Fade>
            <Fade delay={500} duration={2000}>
              <Button onClick={goToHosting}>HOSPEDAJE</Button>
            </Fade>
          </section>
          <section className="about__side">
            <Slide right>
              <img
                src={ImageAbout}
                srcSet={`${ImageAbout2} 2x,
                  ${ImageAbout3} 3x`}
                className="about__side-image"
                alt="side decorator"
              />
            </Slide>
          </section>
        </div>
      </section>
      <section className="home__quote">
        <Fade right>
          <img
            src={ImageLandscape}
            srcSet={`${ImageLandscape2} 2x,
                    ${ImageLandscape3} 3x`}
            className="quote__landscape-image"
            alt="quote landscape"
          />
        </Fade>
        <Fade left>
          <div className="quote__content">
            <h1 className="quote__header">Arte, música y cine en conjunto</h1>
            <h3 className="quote__subheader">con la naturaleza</h3>
            <h1 className="quote__header quote__header--mobile">
              Arte, música y cine
            </h1>
            <h3 className="quote__subheader quote__subheader--mobile">
              en conjunto con la naturaleza
            </h3>
          </div>
        </Fade>
      </section>
      <section className="home__sponsors" id="home-sponsors">
        <Fade down duration={3000}>
          <h3 className="sponsors__header">
            <div className="sponsors__header-decorator" />
            Patrocinado por
            <div className="sponsors__header-decorator" />
          </h3>
        </Fade>
        <Fade down duration={3000} delay={100}>
          <img src={GNPLogo} className="sponsors__company-logo" alt="gnp" />
        </Fade>
        <Fade cascade delay={300} duration={3000}>
          <div className="sponsors__secondary-sponsors">
            {sponsorCompanies.map((sponsor) => (
              <img
                key={sponsor.alt}
                className={`sponsors__secondary-sponsor-item sponsors__secondary-sponsor-item--${sponsor.alt}`}
                src={sponsor.image}
                alt={sponsor.alt}
              />
            ))}
          </div>
        </Fade>
        <Fade duration={3000} delay={450}>
          <h3 className="sponsors__subheader" id="home-collaborators">
            Colaboradores
          </h3>
        </Fade>
        <Fade cascade duration={3000} delay={500}>
          <div className="sponsors__collaborators">
            {collaboratorCompanies.map((collaborator) => (
              <img
                key={collaborator.alt}
                className={`sponsors__collaborator-item sponsors__collaborator-item--${collaborator.alt}`}
                src={collaborator.image}
                alt={collaborator.alt}
              />
            ))}
          </div>
        </Fade>
        {/* Imagen de interseccion */}
        <img
          src={Intersection2Image}
          className="sponsors__image-decorator"
          alt="decorator"
        />
      </section>
    </Layout>
  )
}

export default IndexPage
